<template>
    <div>
        <div class="cajaSuperior">
            <b-row>
                <b-col cols="12" md="3">
                    <h2>Creación de <br /> empresas</h2>
                </b-col>

                <!-- Formulario consulta -->
                <b-col>
                    <div class="contenedorForm pl-5">
                        <FormularioConsultaEmpresas :empresa="empresaEditar"/>
                    </div>
                </b-col>
            </b-row>
        </div>

        <br /> <br />
        <b-row v-if="empresasServicio.length>0">
            <b-col>
                <b-table 
                    id="consultaLicencias" 
                    striped 
                    responsive 
                    small 
                    bordered 
                    :items="empresasServicio" 
                    :fields="camposTabla" 
                    :per-page="perPage"
                    :current-page="currentPage"
                    :tbody-tr-class="rowClass" 
                    select-mode="single"
                    ref="selectableTable"
                    selectable
                    @row-selected="onRowSelected">
                    <template #cell(seleccionar)="{ rowSelected }">
                        <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                    <template #cell(editar)="row">
                        <b-button size="sm" @click="cargarEditar(row.item)" class="mr-1 text-center" variant="link" block>
                            <b-icon icon="pencil" variant="danger"></b-icon>
                        </b-button>
                    </template>
                     <template #cell(eliminar)="row">
                        <b-button size="sm" @click="mandarABorrar(row.item.id)" class="mr-1 text-center" variant="link" block>
                            <b-icon icon="x-circle" variant="danger"></b-icon>
                        </b-button>
                    </template>
                     
                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="empresasServicio.length>0">
            <b-col>
                <b-pagination 
                    v-model="currentPage" 
                    :total-rows="rows" 
                    :per-page="perPage" 
                    aria-controls="consultaLicencias"></b-pagination>
            </b-col>
            <b-col class="text-right">
                <b-button :to="{name: 'agendarVisitaVigilanciaControl'}" :disabled="validarButton">
                    <b-icon icon="plus"></b-icon>
                    Agregar Visita
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import FormularioConsultaEmpresas from '../../components/vigilanciaControl/consultaEmpresas/FormularioConsultaEmpresas.vue'
    import { encontrarError } from '../../contantesErrores';

    export default {
        name: 'ConsultaEmpresas',
        components: {
            FormularioConsultaEmpresas
        },
        async mounted(){
             try{
                 await this.cargarActividadesEconomicas();
                await this.cargarEmpresas();
            }catch(e){
                encontrarError(this, e.data)
            }
        },
        data(){
            return{
                empresaEditar: null,
                camposTabla: [
                    'seleccionar',
                    {
                        key: 'id',
                        label: 'ID Empresa'
                    },
                    {
                        key: 'id_act_economica',
                        label: 'Actividad económica'
                    },
                    {
                        key: 'razon_social',
                        label: 'Razón Social'
                    },
                    {
                        key:'nit',
                        label: 'NIT'
                    },
                    {
                        key:'direccion',
                        label: 'Dirección'
                    },
                    {
                        key:'nombre_representante',
                        label: 'Nombre representante'
                    },
                    {
                        key:'iden_representante',
                        label: 'Identificación representante'
                    },
                    'editar',
                    'eliminar',
                ],
                itemSeleccionado: [],
                perPage: 10,
                currentPage: 1,
            }
        },
        computed:{
            ...mapState('consultaEmpresas', ['empresasServicio']),
           
            rows() {
                return this.empresasServicio.length
            },
            validarButton(){
                if(this.itemSeleccionado.length>0)return false
                return true
            }
        },
        methods: {
            ...mapActions('consultaEmpresas', [
                    'asignarEmpresaSeleccionada', 
                    'cargarEmpresas', 
                    'cargarActividadesEconomicas', 
                    'eliminarEmpresa'
                ]),

            rowClass(item, type) {
                if (!item || type !== 'row') return
                return 'rowspace'
            },

            onRowSelected(items) {
                this.itemSeleccionado = items
                this.asignarEmpresaSeleccionada(items[0])
            },

            cargarEditar(empresa){
                console.log('empresa', empresa)
               this.empresaEditar = empresa
            },

            mandarABorrar(id){
                this.$bvModal.msgBoxConfirm('Se va a eliminar la empresa seleccionada, ¿Desea continuar?.', {
                    title: 'Por favor confirme',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Continuar',
                    cancelTitle: 'Cancelar',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                    })
                    .then(value => {
                        if(value){
                        this.eliminar(id)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            async eliminar(id){
                try{
                    await this.eliminarEmpresa(id)
                    this.cargarEmpresas()
                }catch(e){
                    encontrarError(this, e.data)
                }
            },
        }
    }
</script>