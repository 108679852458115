<template>
    <div>
        <b-form @submit="enviarForm">
            <b-form-row>
                
                <b-col cols="12" md="4">
                    <label  for="actividadEconomica">Actividad económica</label>
                    <b-form-select 
                        id="actividadEconomica" 
                        v-model="form.id_act_economica" 
                        :options="mapearActividadesSelect"
                        class="mb-3"
                    >
                    </b-form-select>
                </b-col>

                <b-col cols="12" md="4">
                    <label  for="nit">NIT</label>
                    <b-form-input
                        id="nit"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="NIT"
                        v-model="form.nit"
                        required
                    ></b-form-input>
                </b-col>
                
                <b-col cols="12" md="4">
                    <label  for="razon_social">Razón Social</label>
                    <b-form-input
                        id="razon_social"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Razón Social"
                        v-model="form.razon_social"
                        required
                    ></b-form-input>
                </b-col>

                 <b-col cols="12" md="4">
                    <label  for="direccion">Dirección</label>
                    <b-form-input
                        id="direccion"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Dirección"
                        v-model="form.direccion"
                        required
                    ></b-form-input>
                </b-col>
                
                <b-col cols="12" md="4">
                    <label  for="nombre_representante">Representante legal</label>
                    <b-form-input
                        id="nombre_representante"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Representante legal"
                        v-model="form.nombre_representante"
                        required
                    ></b-form-input>
                </b-col>
                
                <b-col cols="12" md="4">
                    <label  for="iden_representante">Identificación representante</label>
                    <b-form-input
                        id="iden_representante"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Identificación representante"
                        v-model="form.iden_representante"
                        required
                    ></b-form-input>
                </b-col>

            </b-form-row >

            <b-row align-h="end">

                <b-col cols="12" md="4">
                    <b-button class="mt-4 vertical-align" variant="secondary" type="submit" block v-if="editar">
                        <b-icon icon="plus-circle"></b-icon>
                        Editar
                    </b-button>
                    <b-button class="mt-4 vertical-align" variant="secondary" type="submit" block v-if="!editar">
                        <b-icon icon="plus-circle"></b-icon>
                        Guardar
                    </b-button>
                </b-col>

            </b-row>
        </b-form>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import { encontrarError } from '../../../contantesErrores'

    export default {
        name: 'FormularioConsultaEmpresas',
        props: ['empresa'],
        watch: {
            empresa: function(newVal, oldVal){
                if(newVal!=oldVal){
                    this.mapearCamposEditar()
                    this.editar = true
                }
            }
        },
        data(){
            return{
                editar: false,
                form: {
                    id: null,
                    id_act_economica: null,
                    nit: null,
                    razon_social: null,
                    direccion: null,
                    nombre_representante: null,
                    iden_representante: null,
                }
            }
        },
        async mounted(){
            try{
                this.cargarActividadesEconomicas()
            }catch(e){
                encontrarError(this, e.data)
            }
        },
        computed:{
            ...mapState('consultaEmpresas', ['actividadesEconomicas']),

             mapearActividadesSelect(){
                let mapeadas = this.actividadesEconomicas.map(item=>{
                    let actividad={
                        value: item.id,
                        text: item.descripcion
                    }
                    return actividad
                })
                return mapeadas
            },
        },
        methods:{
            ...mapActions('consultaEmpresas', ['cargarEmpresas','cargarActividadesEconomicas', 'crearEmpresa', 'editarEmpresa']),

            mapearCamposEditar(){
                this.form = {
                    id: this.empresa?this.empresa.id : null,
                    id_act_economica: this.empresa?this.empresa.id_act_economica : null,
                    nit: this.empresa?this.empresa.nit : null,
                    razon_social: this.empresa?this.empresa.razon_social : null,
                    direccion: this.empresa?this.empresa.direccion : null,
                    nombre_representante: this.empresa?this.empresa.nombre_representante : null,
                    iden_representante: this.empresa?this.empresa.iden_representante : null,
                }
            },

            enviarForm(event){
                event.preventDefault()
                
                let mensaje;

                if(this.editar){
                    mensaje= 'Se editara la empresa con los datos suministrados, ¿Desea continuar?.'
                }else{
                    mensaje= 'Se va crear la empresa con los datos suministrados, ¿Desea continuar?.'
                }
                
                this.$bvModal.msgBoxConfirm(mensaje, {
                    title: 'Por favor confirme',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Continuar',
                    cancelTitle: 'Cancelar',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                    })
                    .then(value => {
                        if(value){
                            if(this.editar){
                                this.onEditar();
                            }else{
                                this.onSubmit();
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            async onSubmit(){
                console.log('form', this.form)
                let empresa = {
                    empresa:{
                        ...this.form
                    }
                }
                try{
                    await this.crearEmpresa(empresa)
                    this.modalOk()
                }catch(e){
                    encontrarError(this, e.data)
                }
            },

            async onEditar(){
                let empresa = {
                    empresa:{
                        ...this.form
                    }
                }
                try{
                    await this.editarEmpresa(empresa)
                    this.modalOk()
                }catch(e){
                    encontrarError(this, e.data)
                }
            },

            modalOk(){
                this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                    title: 'Confirmación',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                        this.resetForm()
                        this.cargarEmpresas();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            resetForm(){
                this.editar = false;
                this.form= {
                    id: null,
                    id_act_economica: null,
                    nit: null,
                    razon_social: null,
                    direccion: null,
                    nombre_representante: null,
                    iden_representante: null,
                }
            }

            
        }
    }
</script>

<style scoped>
   
</style>